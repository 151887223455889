@font-face {
  font-family: 'ProximaNova-Regular';
  src: url(./Assets/fonts/ProximaNova-Regular.otf) format('opentype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'ProximaNova-Regular', 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (min-width: 600px) {  
  * {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    overflow: -moz-scrollbars-none;
  }

  *::-webkit-scrollbar {
    scrollbar-width: none;  /* Firefox */
    display: none;  /* Safari and Chrome */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTabScrollButton-root.Mui-disabled {
  opacity: 1;
}
